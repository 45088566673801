import { render, staticRenderFns } from "./SensorConfigDelivery.vue?vue&type=template&id=33bbcbf7&scoped=true&"
import script from "./SensorConfigDelivery.vue?vue&type=script&lang=js&"
export * from "./SensorConfigDelivery.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bbcbf7",
  null
  
)

export default component.exports