<template>
    <b-col cols="12" class="mb-5">
        <h4 class="clr-dark-grey text-center mb-2">PREPARATION LIVRAISON CAPTEUR</h4>
        <h4 class="clr-orange text-center mb-3" v-if="chosenSerialNumbers.length > 0">
            <span v-for="(item,i) in chosenSerialNumbers" :key="`sensor_chose${i}`">
                {{item.serialNumber}} <span v-if="i <chosenSerialNumbers.length - 1">, </span>
            </span>
        </h4>
        <h4 class="clr-orange text-center mb-3 add-cursor" v-else>
            - - -
        </h4>
        <b-row class="justify-content-center align-content-center" v-if="sensors.length > 0">
            <!-- MULTI STEP FORM -->
            <MultiStepForm
                    :next-disable="nextDisable"
                    @confirmStep="confirmStep"
                    @submitForm="completeSetup"
                    form-type="deliveryTracking"
                    v-if="showForm"
            >

                <template v-slot:header>
                    <p class="dashboard-title mb-1 clr-orange">
                        Phase de selection des capteurs pour livraison
<!--                        {{ individualSensor.serialNumber }}-->
                    </p>
                    <p class="card-subtitle clr-light-grey">
                        Suivez les différentes étapes pour préparer l'envoi des capteurs
                    </p>
                </template>
                <template v-slot:step-content>
                    <b-col cols="12" v-if="isLoading">
                        <p class="text-center">
                            <b-spinner class="clr-orange"></b-spinner>
                        </p>
                    </b-col>

                    <!-- SENSOR HARDWARE SETUP STEP -->
                    <b-row class="justify-content-center" v-else>
                        <b-col cols="10" v-if="actualStep === 0">
                            <SensorConfigDelivery :selected-sensors="chosenSerialNumbers" @update-tracking="updateTrackingInformation" />
                        </b-col>
                        <b-col cols="10" v-if="actualStep === 1">
                            <SensorAddAccount :selected-sensors="chosenSerialNumbers" @update-account="updateAccountInformation"/>
                        </b-col>
                        <b-col cols="10" v-if="actualStep === 2">
                            <b-col cols="12" class="mt-4 mb-2">
                                <p class="font-weight-bold">Informations des capteur :</p>
                            </b-col>
                            <b-row class="col-12" v-for="(item, index) in chosenSerialNumbers" :key="`${item.name}_field_index_${index}`">
                                <b-row class="col-12">
                                    <b-col cols="9" class="ml-2">
                                        <p>Numéro de série : <span class="clr-orange">{{ item.serialNumber }}</span></p>
                                    </b-col>
                                </b-row>
                            </b-row>
                            <b-col cols="12" class="mt-4 mb-2">
                                <p class="font-weight-bold">Informations du Suivi :</p>
                            </b-col>
                            <b-row class="col-12">
                                <b-row class="col-12">
                                    <b-col cols="9" class="ml-2">
                                        <p>Numéro de Suivi (TNT) : <span class="clr-orange">{{ trackingInformations.trackingNumber }}</span></p>
                                        <p>ID de Suivi (Lify Air) : <span class="clr-orange">{{ trackingInformations.trackingId }}</span></p>
                                        <p>Status Actuel : <span class="clr-orange">{{ trackingInformations.trackingStatus }}</span></p>
                                    </b-col>
                                </b-row>
                            </b-row>
                            <b-col cols="12" class="mt-4 mb-2">
                                <p class="font-weight-bold">Informations du Compte Associé :</p>
                            </b-col>
                            <b-row class="col-12">
                                <b-row class="col-12">
                                    <b-col cols="9" class="ml-2">
                                        <p>Compte Associé : <span class="clr-orange">{{ accountInformations.chosenAccount }}</span></p>
                                        <p>Groupe Associé : <span class="clr-orange">{{ accountInformations.chosenAccountGroup }}</span></p>
                                        <p>Type d'accès : <span class="clr-orange">{{ accountInformations.accessType }}</span></p>
                                    </b-col>
                                </b-row>
                            </b-row>

                        </b-col>
                    </b-row>
                </template>
            </MultiStepForm>

            <!-- INPUT METHODS FOR SERIAL NUMBER -->
            <b-col cols="12" md="8" class="basic-card mb-4" v-if="!showForm">
                <b-col cols="12" sm="auto">
                    <b-row class="justify-content-around">
                        <b-button class="btn-action mr-4" v-b-modal.modal-scan>
                            <b-icon icon="upc-scan" class="mr-2 text-center"/>
                            Scanner un code
                        </b-button>
                        <b-button class="btn-action mr-4" @click="confirmStep()">
                            <b-icon icon="check-circle" class="mr-2 text-center"/>
                            Valider la section ({{ chosenSerialNumbers.length }})
                        </b-button>
                    </b-row>
                    <b-modal id="modal-scan" title="Scanner un code" :hide-footer="true">
                        <SensorScan @fillInformation="fillInformation()"/>
                    </b-modal>
                </b-col>
            </b-col>

            <!-- SENSORS TABLE -->
            <b-col cols="12" md="8" class="basic-card" v-if="!showForm">
                <!-- TESTING SENSORS TABLE -->
                <div>
                    <b-col cols="12" class="mt-4 mb-1">
                        <h6 class="font-weight-bold">Liste de Capteurs à envoyer</h6>
                    </b-col>
                    <b-col cols="12" class="mt-2 mb-2">
                        <p class="clr-light-grey">Capteur testé ne faisant pas l'objet d'un remplacement</p>
                    </b-col>
                    <b-table-simple
                        small
                        caption-top
                        responsive
                        hover
                        sticky-header
                        class="sensor-array table-responsive table-height bg-white table-borderless"
                        v-if="sensors.length > 0"
                        style="min-height: 55vh"
                    >
                        <colgroup><col><col></colgroup>
                        <colgroup><col><col><col></colgroup>
                        <colgroup><col><col></colgroup>
                        <b-thead class="text-center clr-darker-grey " >
                            <b-tr class="my-2">
                                <b-th class="d-none d-sm-table-cell" ></b-th>
                                <b-th>Numéro de série</b-th>
                                <b-th class="d-none d-lg-table-cell">Date de Création</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr
                                v-for="(item,i) in sensors"
                                :key="i"
                                class="text-center clr-darker-grey add-cursor"
                            >
                                <b-td class="d-sm-table-cell">
                                    <b-form-checkbox
                                        :id="'checkbox-'+item.id"
                                        v-model="item.checked"
                                        name="checkbox-1"
                                        :value="true"
                                        :unchecked-value="false"
                                        @change="setChosenSerialNumbers(item.serialNumber)"
                                    >
                                    </b-form-checkbox>
                                </b-td>
                                <b-td>
                                    {{item.serialNumber}}
                                </b-td>
                                <b-td>{{item.createdAt}}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </b-col>

<!--            &lt;!&ndash; NAVIGATION &ndash;&gt;-->
<!--            <b-row class="col-12 col-md-8 justify-content-between mt-5 p-0" v-if="!showForm">-->
<!--                <b-col cols="12" md="6" class="pl-0">-->
<!--                    <b-button @click="$router.go(-1)" class="w-100 btn-cancel">Annuler</b-button>-->
<!--                </b-col>-->
<!--                <b-col cols="12" md="6" class="pr-0">-->
<!--                    <b-button-->
<!--                            :disabled="!chosenSerialNumbers"-->
<!--                            @click="setSensor"-->
<!--                            class="btn-action w-100">-->
<!--                        Valider-->
<!--                    </b-button>-->
<!--                </b-col>-->
<!--            </b-row>-->
        </b-row>
        <b-row class="justify-content-center align-content-center" v-else>
            <b-col cols="12" md="8" class="basic-card mb-4 text-center font-weight-bold clr-orange">
                <p>
                    Aucun capteur n'est disponible à l'envoi
                </p>
                <br>
                <b-button class="clr-orange border-orange bg-white" @click="$router.go(-1)">
                    RETOUR
                </b-button>
            </b-col>
        </b-row>

        <!-- WARNING WHEN THE CHOSEN SENSOR IS NOT IN STOCK/TEST -->
        <b-modal no-close-on-backdrop
                 ok-only
                 hide-header
                 no-close-on-esc
                 id="modal-wrong-sensor"
                 @ok="$router.go(-1)">
            <p class="h2 text-center clr-orange mt-4">
                <b-icon-exclamation-triangle/>
            </p>
            <p class="my-4 text-center">Ce capteur ne peut pas être testé car il n'est pas en <b>STOCK</b>.</p>
        </b-modal>
    </b-col>
</template>

<script>
import MultiStepForm from "@/components/common/MultiStepForm.vue";
import Vuex from "vuex";
import SensorScan from "@/components/sensor/Management/SensorScan.vue";
import SensorConfigDelivery from "@/components/deliveryTracking/SensorConfigDelivery.vue";
import SensorAddAccount from "@/components/deliveryTracking/SensorAddAccount.vue";
import Toast from "@/services/swal2/mixins";
import {v4 as uuidv4} from 'uuid';

export default {
    name: "TrackSensor",
    components: {
        SensorAddAccount,
        SensorConfigDelivery,
        SensorScan,
        MultiStepForm
    },
    props: {},
    data() {
        return {
            chosenSerialNumbers: [],
            showForm: false,
            isLoading: true,
            sensorType: 'test',
            fields: ['serialNumber', 'createdAt'],
            stockFields: ['serialNumber', 'createdAt'],
            trackingInformations: {},
            accountInformations: {},
            areSensorsReplaced: false
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setIndividualSensors: 'setIndividualSensors',
            setSensors: 'setSensors',
            editSensor:'editSensor'
        }),
        ...Vuex.mapActions('deliveryTracking', {
            setActualStep: 'setActualStep',
            updateFormStep: 'updateFormStep',
            createTracking: 'createTracking',
        }),
        async onDecode(result) {
            const decoded = result.split(';')
            let decodedData = [];
            decoded.forEach((text) => {
                const code = text.split(':');
                const name = code[0];
                decodedData[name] = code[1];
            })
            await this.fillInformation(decodedData)
        },
        onError(err) {
            console.log(err)
        },
        fillInformation(data) {
            this.setChosenSerialNumbers(data['IMEI1']);
        },
        setChosenSerialNumbers(serialNumber) {
            const index = this.chosenSerialNumbers.findIndex(obj => obj.serialNumber === serialNumber);

            if (index !== -1) {
                this.chosenSerialNumbers.splice(index, 1);
            } else {
                let newObject = this.getSensorsById(serialNumber);
                this.chosenSerialNumbers.push(newObject);
            }
        },
        confirmStep() {
            if (!this.showForm){
                this.showForm = true
            } else {
                if (this.actualStep === 0 && this.trackingInformations.installedByLifyair) {
                    this.createTrackingWithoutDelivery();
                } else [
                    this.setActualStep(this.actualStep +1)
                ]
            }
        },
        async completeSetup() {
            for (let i = 0; i < this.chosenSerialNumbers.length; i++) {
                let form = new FormData();
                form.append('status','deploy')
                if (this.trackingInformations.installedByLifyair) {
                    form.append('statusStep', '2')
                } else {
                    form.append('statusStep', '1')
                }
                let data = [this.chosenSerialNumbers[i].serialNumber,form]
                await this.editSensor(data)
                if (i+1 ===this.chosenSerialNumbers.length){
                    this.$router.go(-1)
                }
            }
        },
        updateTrackingInformation(data){
            this.trackingInformations = data
        },
        updateAccountInformation(data){
            this.accountInformations = data
        },
        async createTrackingWithoutDelivery() {
            let form = new FormData()
            const trackingId = uuidv4();
            this.trackingInformations.trackingId = trackingId;
            form.append('trackingId', trackingId)
            for (let i = 0; i < this.chosenSerialNumbers.length; i++) {
                form.append('sensors[]', this.chosenSerialNumbers[i].serialNumber)
            }
            await this.createTracking(form).then((isOk) => {
                if (isOk) {
                    this.updateFormStep().then(() => this.setActualStep(this.actualStep + 1));
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite pendant la création suivi.',
                        timer: 1000
                    })
                }
            });
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            adminStockedSensors: 'adminStockedSensors',
            sensors: 'sensors',
            getSensorsById:'getSensorsById'
        }),
        ...Vuex.mapGetters('deliveryTracking', {
            actualStep: 'actualStep',
            steps: 'steps',
        }),
        nextDisable() {
            switch (this.actualStep) {
                case 0:
                    if ('installedByLifyair' in this.trackingInformations) {
                        return !(this.trackingInformations.installedByLifyair);
                    } else {
                        return !(this.trackingInformations.trackingNumber);
                    }
                case 1:
                    return !(this.sensorSetup && this.sensorSetup.rawData);
                default:
                    return false;
            }
        },
        serialNumbers() {
            return this.sensors.map(item => item.serialNumber)
        }
    },
    async created() {
        await this.setSensors({status:'test',statusStep:2, paginated: false});
        this.isLoading = false;
    },
    watch:{
        areSensorsReplaced: {
            handler(value) {
                if (value){
                    this.$router.push({
                        name: 'Deliveries',
                        query: { deliveryType: 'returning' },
                    });
                } else {
                    this.$router.push({
                        path: 'Deliveries',
                        query: { deliveryType: 'shipping' },
                    });
                }
            }
        }
    },
}
</script>

<style scoped>
</style>