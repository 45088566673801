<template>
    <b-col cols="12" class="mb-5">
        <h4 class="clr-dark-grey text-center mb-2">PREPARATION RETOUR CAPTEUR</h4>
        <h4 class="clr-orange text-center mb-3" v-if="chosenSerialNumbers.length > 0">
            <span v-for="(item,i) in chosenSerialNumbers" :key="`sensor_chose${i}`">
                {{ item.serialNumber }} <span v-if="i <chosenSerialNumbers.length - 1">, </span>
            </span>
        </h4>
        <h4 class="clr-orange text-center mb-3 add-cursor" v-else>
            - - -
        </h4>
        <b-row class="justify-content-center align-content-center"
               v-if="sensors.length > 0 || chosenSerialNumbers.length > 0">
            <!-- MULTI STEP FORM -->
            <MultiStepForm
                :next-disable="nextDisable"
                @confirmStep="confirmStep"
                @submitForm="completeSetup"
                form-type="deliveryTracking"
                v-if="showForm"
            >

                <template v-slot:header>
                    <p class="dashboard-title mb-1 clr-orange">
                        Phase de selection des capteurs pour retour chez Lify Air
                        <!--                        {{ individualSensor.serialNumber }}-->
                    </p>
                    <p class="card-subtitle clr-light-grey">
                        Suivez les différentes étapes pour préparer le retour des capteurs
                    </p>
                </template>
                <template v-slot:step-content>
                    <b-col cols="12" v-if="isLoading">
                        <p class="text-center">
                            <b-spinner class="clr-orange"></b-spinner>
                        </p>
                    </b-col>
                    <b-row class="justify-content-center" v-else>
                        <!-- REPLACING SENSORS CHOICE -->
                        <b-col cols="10" v-if="actualStep === 0">
                            <b-row class="align-content-start align-items-start justify-content-start">
                                <b-col cols="12" class="mt-4 mb-3">
                                    <h6 class="font-weight-bold">Les capteurs vont ils être remplacé ?</h6>
                                    <p class="clr-light-grey">concerne les capteurs qui sont rappelés par Lify Air</p>
                                </b-col>
                                <b-col offset="1"
                                       cols="4"
                                       :class="!areSensorsReplaced ? 'clr-orange':'clr-darker-grey'"
                                       @click="areSensorsReplaced = !areSensorsReplaced"
                                       class="add-cursor"
                                >
                                    Non, retour simple sans remplacement
                                </b-col>
                                <b-col cols="1">
                                    <b-form-checkbox v-model="areSensorsReplaced" name="check-button" switch>
                                    </b-form-checkbox>
                                </b-col>
                                <b-col :class="areSensorsReplaced ? 'clr-orange':'clr-darker-grey'"
                                       @click="areSensorsReplaced = !areSensorsReplaced"
                                       class="add-cursor"
                                >
                                    Oui, retour des capteurs avec remplacement
                                </b-col>
                            </b-row>
                            <b-row class="align-content-start align-items-start justify-content-start">
                                <b-col cols="12" class="mt-4 mb-3" v-if="areSensorsReplaced">
                                    <h6 class="font-weight-bold">Liste de Capteurs de remplacement</h6>
                                    <p class="clr-light-grey">qui seront envoyé par Lify Air en remplacement de capteurs
                                        dysfonctionnels</p>
                                </b-col>
                            </b-row>
                            <SensorDeliveryTable
                                class="mt-5"
                                :sensors="sensors"
                                :chosen-serial-numbers="chosenSerialNumbers"
                                :max-sensor="chosenSerialNumbers.length"
                                @set-chosen-serialnumber="setChosenSerialNumbersForReplacement"
                                v-if="areSensorsReplaced"
                            />
                        </b-col>
                        <!-- DELIVERY CONFIGURATION -->
                        <b-col cols="10" v-if="actualStep === 1">
                            <SensorConfigDelivery
                                class="mt-5"
                                :selected-sensors="chosenSerialNumbersForReplacement"
                                @update-tracking="updateTrackingInformation"
                                v-if="chosenSerialNumbersForReplacement.length > 0"
                                :key="1"
                            />
                            <div v-else>
                                <p class="mt-0 mt-md-5"></p>
                                Aucun capteur à envoyer pour le remplacement, vous pouvez passer à l'étape suivante.
                            </div>
                        </b-col>
                        <!-- RETURNING SENSOR GENERATION -->
                        <b-col cols="10" v-if="actualStep === 2">
                            <SensorGenerateReturn
                                :is-installed-by-lifyair="trackingInformations.installedByLifyair"
                                :replacing-account-group="replacingAccountGroup"
                                :are-sensors-replaced="areSensorsReplaced"
                                :selected-returning-sensors="chosenSerialNumbers"
                                :selected-replacing-sensors="chosenSerialNumbersForReplacement">
                            </SensorGenerateReturn>
                        </b-col>
                        <!-- RETURNING SENSOR RESUME -->
                        <b-col cols="10" v-if="actualStep === 3">
                            <b-row v-if="areSensorsReplaced">
                                <b-col cols="12" class="mt-4 mb-2">
                                    <p class="font-weight-bold">Capteurs remplacés :</p>
                                </b-col>
                                <b-col class="col-12" v-for="(item, index) in chosenSerialNumbers"
                                       :key="`${item.name}_field_index_${index}`">
                                    <b-row class="col-12">
                                        <b-col cols="9" class="ml-2">
                                            <p>Numéro de série : <span class="clr-orange">{{ item.serialNumber }}</span>
                                            </p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="mt-1 mb-2">
                                    <p class="clr-light-grey">Capteur à réceptionner dans les prochains jours</p>
                                </b-col>
                            </b-row>
                            <b-row v-else>
                                <b-col cols="12" class="mt-4 mb-2">
                                    <p class="font-weight-bold">Capteurs retournés :</p>
                                </b-col>
                                <b-col class="col-12" v-for="(item, index) in chosenSerialNumbers"
                                       :key="`${item.name}_field_index_${index}`">
                                    <b-row class="col-12">
                                        <b-col cols="9" class="ml-2">
                                            <p>Numéro de série : <span class="clr-orange">{{ item.serialNumber }}</span>
                                            </p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="mt-1 mb-2">
                                    <p class="clr-light-grey">Capteur à réceptionner dans les prochains jours</p>
                                </b-col>
                            </b-row>
                            <b-row v-if="chosenSerialNumbersForReplacement.length > 0">
                                <b-col cols="12" class="mt-3 mb-2">
                                    <p class="font-weight-bold">Capteurs de remplacements :</p>
                                </b-col>
                                <b-col class="col-12" v-for="(item, index) in chosenSerialNumbersForReplacement"
                                       :key="`${item.name}_field_index_${index}`">
                                    <b-row class="col-12">
                                        <b-col cols="9" class="ml-2">
                                            <p>Numéro de série : <span class="clr-orange">{{ item.serialNumber }}</span>
                                            </p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" class="mt-2 mb-2">
                                    <p class="clr-light-grey">Capteur à envoyer dans les prochains jours</p>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </template>
            </MultiStepForm>

            <!-- INPUT METHODS FOR SERIAL NUMBER -->
            <b-col cols="12" md="8" class="basic-card mb-4" v-if="!showForm">
                <b-col cols="12" sm="auto">
                    <b-row class="justify-content-around">
                        <b-button class="btn-action mr-4" v-b-modal.modal-scan>
                            <b-icon icon="upc-scan" class="mr-2 text-center"/>
                            Scanner un code
                        </b-button>
                        <b-button class="btn-action mr-4" @click="confirmStep()">
                            <b-icon icon="check-circle" class="mr-2 text-center"/>
                            Valider la section ({{ chosenSerialNumbers.length }})
                        </b-button>
                    </b-row>
                    <b-modal id="modal-scan" title="Scanner un code" :hide-footer="true">
                        <SensorScan @fillInformation="fillInformation()"/>
                    </b-modal>
                </b-col>
            </b-col>

            <!-- SENSORS TABLE -->
            <b-col cols="12" md="8" class="basic-card" v-if="!showForm">
                <!-- TESTING SENSORS TABLE -->
                <b-col cols="12" class="mt-2 mb-1">
                    <h6 class="font-weight-bold">Liste de capteurs pour retour</h6>
                </b-col>
                <b-col cols="12" class="mt-2 mb-2">
                    <p class="clr-light-grey">Capteurs dysfonctionnels sur le terrain et mis en quarantaines</p>
                </b-col>
                <div>
                    <SensorDeliveryTable
                        :sensors="sensors"
                        :chosen-serial-numbers="chosenSerialNumbers"
                        @set-chosen-serialnumber="setChosenSerialNumbers"
                    ></SensorDeliveryTable>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-center align-content-center"
               v-else-if="chosenSerialNumbers.length === 0 && sensors.length === 0">
            <b-col cols="12" md="8" class="basic-card mb-4 text-center font-weight-bold clr-orange">
                <p>
                    Aucun capteur n'est disponible pour un retour
                </p>
                <br>
                <b-button class="clr-orange border-orange bg-white" @click="$router.go(-1)">
                    RETOUR
                </b-button>
            </b-col>
        </b-row>

        <!-- WARNING WHEN THE CHOSEN SENSOR IS NOT IN STOCK/TEST -->
        <b-modal no-close-on-backdrop
                 ok-only
                 hide-header
                 no-close-on-esc
                 id="modal-wrong-sensor"
                 @ok="$router.go(-1)">
            <p class="h2 text-center clr-orange mt-4">
                <b-icon-exclamation-triangle/>
            </p>
            <p class="my-4 text-center">Ce capteur ne peut pas être testé car il n'est pas en <b>STOCK</b>.</p>
        </b-modal>
    </b-col>
</template>

<script>
import MultiStepForm from "@/components/common/MultiStepForm.vue";
import Vuex from "vuex";
import SensorScan from "@/components/sensor/Management/SensorScan.vue";
import SensorConfigDelivery from "@/components/deliveryTracking/SensorConfigDelivery.vue";
import SensorDeliveryTable from "@/components/deliveryTracking/SensorDeliveryTable.vue";
import SensorGenerateReturn from "@/components/deliveryTracking/SensorGenerateReturn.vue";

export default {
    name: "TrackReturningSensor",
    components: {
        SensorGenerateReturn,
        SensorDeliveryTable,
        SensorConfigDelivery,
        SensorScan,
        MultiStepForm
    },
    props: {},
    data() {
        return {
            chosenSerialNumbers: [],
            chosenSerialNumbersForReplacement: [],
            showForm: false,
            isLoading: true,
            fields: ['serialNumber', 'createdAt'],
            trackingInformations: {},
            isLifyManagingReturn: false,
            areSensorsReplaced: false,
            replacingAccountGroup: null
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors'
        }),
        ...Vuex.mapActions('deliveryTracking', {
            setActualStep: 'setActualStep',
            setIsReturning: 'setIsReturning',
            resetActualStep: 'resetActualStep',
            updateFormStep: 'updateFormStep',
            resetData: 'resetData'
        }),
        generateQueryString() {
            let data = []

            for (let i = 0; i < this.chosenSerialNumbers.length; i++) {
                let form = []
                form.sensor = this.chosenSerialNumbers[i];
                let fields = new FormData();
                fields.append('accountGroup', 1);
                fields.append('accessType', 'standard')
                form.data = fields
                form.multiPush = true
                data.push(form)
            }
            return data
        },
        fillInformation(data) {
            this.setChosenSerialNumbers(data['IMEI1']);
        },
        setChosenSerialNumbers(serialNumber) {
            const index = this.chosenSerialNumbers.findIndex(obj => obj.serialNumber === serialNumber);

            if (index !== -1) {
                this.chosenSerialNumbers.splice(index, 1);
            } else {
                let newObject = this.getSensorsById(serialNumber);
                this.chosenSerialNumbers.push(newObject);
                this.replacingAccountGroup = newObject.owningAccountGroup.id;
            }
        },
        setChosenSerialNumbersForReplacement(serialNumber) {
            const index = this.chosenSerialNumbersForReplacement.indexOf(serialNumber);

            if (index !== -1) {
                this.chosenSerialNumbersForReplacement.splice(index, 1);
            } else {
                let newObject = this.getSensorsById(serialNumber);
                this.chosenSerialNumbersForReplacement.push(newObject);
            }
        },
        confirmStep() {
            if (!this.showForm) {
                this.showForm = true
            } else {
                this.updateFormStep()
                this.setActualStep(this.actualStep + 1)
            }
        },
        completeSetup() {
            this.resetData().then(() => {
                setTimeout(() => {
                    this.$router.push('/')
                }, 500)
            })
        },
        updateTrackingInformation(data) {
            Object.assign(this.trackingInformations, this.trackingInformations, data);
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            individualSensor: 'individualSensor',
            sensors: 'sensors',
            getSensorsById: 'getSensorsById'
        }),
        ...Vuex.mapGetters('deliveryTracking', {
            actualStep: 'actualStep'
        }),
        nextDisable() {
            if (this.actualStep === 0) {
                return this.areSensorsReplaced && !(this.areSensorsReplaced && this.chosenSerialNumbersForReplacement.length > 0);
            }
            return false;
        }
    },
    async created() {
        await this.setActualStep(0)
        await this.setIsReturning();
        await this.setSensors({status: 'prod', statusStep: 3, paginated: false});

        this.isLoading = false;
    },
    watch: {
        areSensorsReplaced: {
            async handler(value) {
                if (value) {
                    await this.setSensors({status: 'test', statusStep: 2, paginated: false});

                }
            }
        }
    },
    async destroyed() {
        await this.resetActualStep()
    }
}
</script>

<style scoped>
</style>