<script>
export default {
    name: "SensorDeliveryTable",
    props:{
        sensors: {
            type: Array,
            default() {
                return []
            }
        },
        chosenSerialNumbers: {
            type: Array,
            default() {
                return []
            }
        },
        maxSensor: {
            type: Number,
            default:null,
            required:false
        },
    },
    methods:{
        shouldDisableCheckBox(serialNumber) {
            let alreadyExist = this.sensors.find(item => item.serialNumber === serialNumber && item.checked === true)
            if (this.maxSensor !== null){
                return (!alreadyExist && !(this.totalChosenSensors < this.maxSensor))
            }
            return false;
        },
        setChosenSerialNumbers(serialNumber){
            this.$emit('set-chosen-serialnumber', serialNumber)
        }
    },
    computed:{
        totalChosenSensors(){
            return this.sensors.filter(item => item.checked === true).length
        }
    }
}
</script>

<template>
    <b-table-simple
            small
            caption-top
            responsive
            hover
            sticky-header
            class="sensor-array table-responsive table-height bg-white table-borderless"
            v-if="sensors.length > 0"
            style="min-height: 55vh"
    >
        <colgroup><col><col></colgroup>
        <colgroup><col><col><col></colgroup>
        <colgroup><col><col></colgroup>
        <b-thead class="text-center clr-darker-grey " >
            <b-tr class="my-2">
                <b-th class="d-none d-sm-table-cell" ></b-th>
                <b-th>Numéro de série</b-th>
                <b-th class="d-none d-lg-table-cell">Date de Création</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr
                    v-for="(item,i) in sensors"
                    :key="i"
                    class="text-center clr-darker-grey add-cursor"
            >
                <b-td class="d-sm-table-cell">
                    <b-form-checkbox
                        :id="'checkbox-'+item.id"
                        v-model="item.checked"
                        name="checkbox-1"
                        :value="true"
                        :unchecked-value="false"
                        @change="setChosenSerialNumbers(item.serialNumber)"
                        :disabled="shouldDisableCheckBox(item.serialNumber)"
                    >
                    </b-form-checkbox>
                </b-td>
                <b-td>
                    {{item.serialNumber}}
                </b-td>
                <b-td>{{item.createdAt}}</b-td>
            </b-tr>
        </b-tbody>
        <b-tfoot v-if="maxSensor">
            <b-tr>
                <b-td colspan="7" variant="secondary" class="text-right clr-darker-grey bg-lighter-grey">
                    Capteur(s) Choisi(s) : <b>{{ totalChosenSensors }} / {{ maxSensor }}</b>
                </b-td>
            </b-tr>
        </b-tfoot>
    </b-table-simple>
    <b-row class="justify-content-center align-content-center" v-else>
        <b-col cols="12" class="basic-card mb-4 text-center font-weight-bold clr-orange">
            <p>
                Aucun capteur n'est disponible
            </p>
            <br>
            <b-button class="clr-orange border-orange bg-white" @click="$router.go(-1)">
                RETOUR
            </b-button>
        </b-col>
    </b-row>
</template>

<style scoped lang="scss">

</style>