<template>
    <div class="my-5 col-xl-12 text-center">
        <div style="font-size: 4rem;">
            <b-icon
                :icon="steps[step].icon"
                :class="steps.length === step + 1 ? 'clr-orange':'clr-darker-grey'"/>
        </div>
        <h6 class="my-3 mb-0 clr-light-grey">{{ steps[step].name }}</h6>
        <b-progress
            :value="step + 1"
            :max="steps.length"
            variant="primary"
            striped
            :animated="!isGenerationDone">
        </b-progress>
    </div>
</template>

<script>
import Vuex from "vuex";
import dataFormatter from "@/services/utils/dataFormatter";

export default {
    name: "SensorGenerateReturn",
    props: {
        selectedReturningSensors: Array,
        selectedReplacingSensors: Array,
        replacingAccountGroup: Number,
        areSensorsReplaced: Boolean,
        isInstalledByLifyair: Boolean,
    },
    data() {
        return {
            isGenerationDone: false,
            step: 0,
            stepsWithReplacement: [
                {
                    name: 'Lancement de la configuration de la fiche retour...',
                    icon: 'card-list',
                    subCategories: []
                },
                {
                    name: 'Ré-intégration au stock Lify Air...',
                    icon: 'cloud-arrow-down-fill',
                    subCategories: []
                },
                {
                    name: 'Génération des données de Remplacement...',
                    icon: 'clock-history',
                    subCategories: []
                },
                {
                    name: 'Mise à jour du statut des capteurs RETOUR',
                    icon: 'arrow-down-circle',
                    subCategories: []
                },
                {
                    name: 'Mise à jour du statut des capteurs REMPLAÇANT',
                    icon: 'arrow-up-circle',
                    subCategories: []
                },
                {
                    name: 'Vérification terminée',
                    icon: 'check-circle-fill',
                    subCategories: []
                }
            ],
            stepsWithoutReplacement: [
                {
                    name: 'Lancement de la configuration de la fiche retour...',
                    icon: 'card-list',
                    subCategories: []
                },
                {
                    name: 'Ré-intégration au stock Lify Air...',
                    icon: 'cloud-arrow-down-fill',
                    subCategories: []
                },
                {
                    name: 'Mise à jour du statut des capteurs RETOUR',
                    icon: 'arrow-down-circle',
                    subCategories: []
                },
                {
                    name: 'Vérification terminée',
                    icon: 'check-circle-fill',
                    subCategories: []
                }
            ]
        }
    },
    computed: {
        chosenReturningSensorsSerialNumbers() {
            return this.selectedReturningSensors.map(item => item.serialNumber)
        },
        chosenReplacingSensorsSerialNumbers() {
            return this.selectedReplacingSensors.map(item => item.serialNumber)
        },
        steps() {
            if (this.areSensorsReplaced) {
                return this.stepsWithReplacement
            } else {
                return this.stepsWithoutReplacement
            }
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            changeSensorAccount: 'changeSensorAccount',
            createNewReturningSheet: 'createNewReturningSheet',
            editSensor: 'editSensor'
        }),
        async changeAccount() {
            let queries = this.generateQueryStringForAccountChange()
            for (let i = 0; i < queries.length; i++) {
                await this.changeSensorAccount(queries[i]);
            }
        },
        async gatherInformationForReplacementSensors() {
            if (this.selectedReturningSensors.length > 0) {
                let returnSheetPayload = []
                for (let i = 0; i < this.selectedReturningSensors.length; i++) {
                    const payload = dataFormatter.formatSensorPayload(this.selectedReturningSensors[i])
                    returnSheetPayload.push(payload)
                }
                let queries = this.generateQueryStringForSensorReplacement(returnSheetPayload)
                await this.createNewReturningSheet(queries);
            }
        },
        generateQueryStringForAccountChange() {
            let data = []
            for (let i = 0; i < this.selectedReturningSensors.length; i++) {
                let form = []
                form.sensor = this.selectedReturningSensors[i].serialNumber;
                let fields = new FormData();
                fields.append('accountGroup', 1);
                fields.append('accessType', 'standard')
                form.data = fields
                form.multiPush = true
                data.push(form)
            }
            return data
        },
        generateQueryStringForSensorReplacement(returnSheetPayload) {
            let fields = new FormData();

            for (let i = 0; i < this.selectedReturningSensors.map(item => item.serialNumber).length; i++) {
                fields.append('sensorsReturning[]', this.selectedReturningSensors.map(item => item.serialNumber)[i]);
            }

            for (let i = 0; i < this.selectedReplacingSensors.map(item => item.serialNumber).length; i++) {
                fields.append('replacementSensors[]', this.selectedReplacingSensors.map(item => item.serialNumber)[i]);
            }

            fields.append('replacementPayload', JSON.stringify(returnSheetPayload));
            fields.append('replacingAccountGroup', '' + this.replacingAccountGroup);
            return fields
        },
        async generateReturnConfiguration() {
            this.step += 1;

            await this.gatherInformationForReplacementSensors()
            setTimeout(() => {
                this.step += 1;
            }, 2500)

            await this.changeAccount()
            setTimeout(() => {
                this.step += 1;
            }, 2500)

            for (let i = 0; i < this.chosenReturningSensorsSerialNumbers.length; i++) {
                let form = new FormData();
                form.append('status', 'stock')
                form.append('statusStep', '2')
                let data = [this.chosenReturningSensorsSerialNumbers[i], form]
                await this.editSensor(data)
                if (i + 1 === this.chosenReturningSensorsSerialNumbers.length) {
                    setTimeout(() => {
                        this.step += 1;
                    }, 2500)
                }
            }

            for (let i = 0; i < this.chosenReplacingSensorsSerialNumbers.length; i++) {
                let form = new FormData();
                form.append('status', 'deploy')
                if (this.isInstalledByLifyair) {
                    form.append('statusStep', '2')
                } else {
                    form.append('statusStep', '1')
                }
                let data = [this.chosenReplacingSensorsSerialNumbers[i], form]
                await this.editSensor(data)
                if (i + 1 === this.chosenReplacingSensorsSerialNumbers.length) {
                    setTimeout(() => {
                        this.step += 1;
                    }, 2500)
                }
            }
            this.isGenerationDone = true;
        }
    },
    mounted() {
        this.generateReturnConfiguration()
    }
}
</script>

<style lang="scss" scoped>
.error-type-badge {
    background-color: $indianred;
    color: $white;
    font-size: 18px;
    margin-right: 20px;
}

.download-raw-data-btn {
    margin-top: -7rem;
}
</style>