<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group
                    label="Utiliser le système de suivi Ship24 ?"
                    label-for="tracking-choice"
                    label-class="bold"
                    description="Si OUI la statut du capteur sera mis à jour en temps réel.">
                    <b-form-checkbox
                        id="tracking-choice"
                        v-model="useShip24"
                        name="check-tracking-choice"
                        switch>
                        <span class="bold mt-2" :class="useShip24 ? 'clr-orange' : ''">
                            {{ useShip24 ? 'OUI' : 'NON' }}
                        </span>
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group
                    label="Le capteur sera installé directement par un technicien Lifyair sur place ?"
                    label-for="installation-choice"
                    label-class="bold">
                    <b-form-checkbox
                        id="installation-choice"
                        v-model="installedByLifyair"
                        @change="updateTrackingInformations"
                        name="check-installation-choice"
                        switch>
                        <span class="bold mt-2" :class="installedByLifyair ? 'clr-orange' : ''">
                            {{ installedByLifyair ? 'OUI' : 'NON' }}
                        </span>
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <div v-if="!installedByLifyair">
            <b-row class="mt-3 mb-5" inline v-for="(group,i) in groups" :key="i">
                <b-col cols="12">
                    <p class="col-12 p-0 font-weight-bold">Numéro du Bon de transport N°{{ i + 1 }} (<span
                        class="font-weight-lighter">à créer ici </span>
                        <a href="https://www.tnt.fr/mytnt/index.do" target="_blank" class="clr-orange">TNT</a>
                        ) :
                    </p>
                </b-col>
                <b-col cols="12" v-if="selectedSensors.length > 0">
                    Capteur dans le colis :
                    <b-badge
                        v-for="(serialNumber,i) in group.map(item => item.serialNumber)"
                        :key="i"
                        class="mr-1 clr-white bg-orange"
                    >
                        {{ serialNumber }}
                    </b-badge>
                </b-col>
                <b-col cols="12">
                    <b-form class="row" @submit.prevent="submit(i)">
                        <b-form-input
                            id="simId-input"
                            class="col-10 col-md-7 text-center border-right-0"
                            v-model="trackingNumber[i]">
                        </b-form-input>
                        <b-button
                            :disabled="!trackingNumber[i] ? true : trackingNumber[i].length < 8"
                            class="btn-action no-border-left shadow-none col-2"
                            type="submit">
                            OK
                        </b-button>
                        <b-col cols="3" class="clr-orange">
                            <p class="h4">
                                <b-icon-check-circle v-if="formCompleted[actualStep]"/>
                            </p>
                        </b-col>
                    </b-form>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Vuex from "vuex";
import axios from "axios";
import Toast from "@/services/swal2/mixins";

export default {
    name: "SensorConfigDelivery",
    props: {
        selectedSensors: Array
    },
    data() {
        return {
            trackingNumber: {},
            trackingId: '',
            trackingStatus: 'in_transit',
            apiKey: 'apik_9XGicVDn6ltMOdyvnwjbDw35pB1WIt',
            groups: [],
            formCompleted: {},
            useShip24: false,
            installedByLifyair: false,
        }
    },
    methods: {
        ...Vuex.mapActions('deliveryTracking', {
            createTracking: 'createTracking',
            setActualStep: 'setActualStep',
            updateFormStep: 'updateFormStep'
        }),
        async addNewTracker(formID) {
            await axios.post(
                "https://api.ship24.com/public/v1/trackers",
                {"trackingNumber": this.trackingNumber[formID]},
                {
                    headers: {
                        'Authorization': "Bearer " + this.apiKey,
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                }
            ).then((response) => {
                Toast.fire(
                    {
                        icon: 'success',
                        title: 'Suivi N°' + formID + ' ajouté avec succès !',
                    }
                )

                this.trackingId = response.data.data.tracker.trackerId
            }).catch((error) => {
                Toast.fire(
                    {
                        icon: 'error',
                        title: error,
                    }
                )
                console.warn(error)
            });
        },
        async submit(formID) {
            if (this.installedByLifyair) {
                await this.setActualStep(this.actualStep + 1)
            } else {
                if (this.useShip24) {
                    await this.addNewTracker(formID)
                }
                await this.createTrackingAndSubmit(formID);
            }
        },
        splitSensorsIntoGroups(sensors) {
            let group = [];

            for (let i = 0; i < sensors.length; i++) {
                group.push(sensors[i]);

                if (group.length === 3 || i === sensors.length - 1) {
                    this.groups.push(group);
                    group = [];
                }
            }
        },
        async createTrackingAndSubmit(sensorGroup) {
            const actualTrackingNumber = this.trackingNumber[sensorGroup];

            if (!this.trackingId || this.trackingId === '') this.trackingId = actualTrackingNumber
            if (this.groups[sensorGroup].length > 0) {
                let form = new FormData()
                for (let i = 0; i < this.groups[sensorGroup].length; i++) {
                    form.append('sensors[]', this.groups[sensorGroup][i].serialNumber)
                }

                form.append('trackingNumber', actualTrackingNumber)
                form.append('trackingId', this.trackingId)
                form.append('trackingStatus', this.trackingStatus)

                await this.createTracking(form).then((isOK) => {
                    if (isOK) {
                        this.$emit('update-tracking', {
                            'trackingNumber': actualTrackingNumber,
                            'trackingId': this.trackingId,
                            'trackingStatus': this.trackingStatus
                        })
                        if (sensorGroup + 1 === this.groups.length) {
                            this.setActualStep(this.actualStep + 1)
                        }
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Le Numéro de suivi à déja été utilisé',
                            timer: 1000
                        })
                    }
                }).catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Une erreur s\'est produite pendant la création suivi.',
                        timer: 1000
                    })
                })
            }
        },
        updateTrackingInformations() {
            this.$emit('update-tracking', {
                'installedByLifyair': this.installedByLifyair,
            })
        }
    },
    computed: {
        ...Vuex.mapGetters('deliveryTracking', {
            actualStep: 'actualStep'
        })
    },
    created() {
        this.splitSensorsIntoGroups(this.selectedSensors)
    },
}
</script>

<style scoped>

</style>