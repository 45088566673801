<template>
    <div>
        <b-form @submit.prevent="submit().then(()=>{changeStep()})">
              <p class="h5 clr-orange">Affectation</p>
              <b-form-group
                  id="input-group-4"
                  label="Veuillez sélectionner le compte destinataire:"
                  label-for="account"
                  label-class="clr-light-grey"
              >
                  <b-form-input list="account-list" class="mb-3" placeholder="Compte"
                                :state="chosenAccount !== '' ? true : null" v-model="chosenAccount"/>
                  <datalist id="account-list">
                      <option v-for="(account, index) in accounts" :key="index" :value="account.name">
                          {{ account.name }}
                      </option>
                  </datalist>
              </b-form-group>
              <b-form-group
                  label="Veuillez sélectionner un groupe à associer :"
                  v-slot="{ ariaDescribedby }"
                  label-for="accountGroups" class="dataAccessFormSubTitle clr-dark-grey" v-if="chosenAccount">
                  <b-form-select v-model="chosenAccountGroup" id="accounts" required
                                 :state="chosenAccountGroup !== '' ? true : null">
                      <b-form-select-option :aria-describedby="ariaDescribedby"
                                            :value="group.id" v-for="(group, index) in groupsBasedOnChosenAccounts"
                                            :key="index">
                          {{ group.name }}
                      </b-form-select-option>
                  </b-form-select>
              </b-form-group>
              <b-form-group label="Type d'accès :" label-for="accessType"
                            class="dataAccessFormSubTitle clr-dark-grey" v-if="chosenAccount && chosenAccountGroup">
                  <b-form-select :state="accessType !== '' ? true : null"
                                 v-model="accessType" :options="accessTypes"/>
              </b-form-group>
              <div class="text-center">
                  <b-button type="submit" variant="primary" class="mr-2">Valider</b-button>
              </div>
        </b-form>
    </div>
</template>

<script>
import Vuex from "vuex";
// import axios from "axios";

export default {
    name: "SensorAddAccount",
    props: {
      selectedSensors:Array
    },
    data() {
        return {
            chosenAccount: '',
            chosenAccountGroup: '',
            accessType: 'standard',
            sensor: null,
            accessTypes: [
                {value: 'standard', text: 'Standard'},
                {value: 'pro', text: 'Entreprise'},
                {value: 'scientific', text: 'Scientifique'}
            ],
        }
    },
    methods: {
        ...Vuex.mapActions('deliveryTracking',{
            createTracking:'createTracking',
            setActualStep:'setActualStep',
            updateFormStep:'updateFormStep'
        }),
        ...Vuex.mapActions('account',{
            setAccounts:'setAccounts',
        }),
        ...Vuex.mapActions('sensor', {
            changeSensorAccount: 'changeSensorAccount',
        }),
        async submit() {
            let queries = this.generateQueryString()
            for (let i = 0; i < queries.length; i++) {
                await this.changeSensorAccount(queries[i]);
            }

        },
        generateQueryString() {
            let data = []

            for (let i = 0; i < this.selectedSensors.length; i++) {
                let form = []
                form.sensor = this.selectedSensors[i].serialNumber;
                let fields = new FormData();
                if (this.chosenAccountGroup !== '') {
                    fields.append('accountGroup', this.chosenAccountGroup);
                }

                fields.append('accessType', this.accessType)
                form.data = fields
                form.multiPush = true
                data.push(form)
            }

            this.$emit('update-account', {
                'chosenAccount': this.chosenAccount,
                'chosenAccountGroup': this.chosenAccountGroup,
                'accessType': this.accessType
            })
            return data
        },
        changeStep(){
            this.updateFormStep().then(() => {
                this.setActualStep(this.actualStep + 1)
            })
        }
    },
    computed: {
        ...Vuex.mapGetters('deliveryTracking', {
            createdTracking:'createdTracking',
            actualStep:'actualStep'
        }),
        ...Vuex.mapGetters('account', {
            accounts: 'accounts',
            getAccountGroupsByAccountName: 'getAccountGroupsByAccountName',

        }),
        groupsBasedOnChosenAccounts() {
            return this.getAccountGroupsByAccountName(this.chosenAccount)
        }
    },
    created() {
        this.setAccounts()
    }
}
</script>

<style scoped>

</style>