<template>
    <b-container fluid>
        <TrackSensor v-if="deliveryType === 'shipping'"></TrackSensor>
        <TrackReturningSensor v-else-if="deliveryType === 'returning'"></TrackReturningSensor>
        <SensorReturningSheets v-else></SensorReturningSheets>
    </b-container>
</template>

<script>
import Vuex from "vuex";
import TrackSensor from "@/components/deliveryTracking/TrackSensor.vue";
import TrackReturningSensor from "@/components/deliveryTracking/TrackReturningSensor.vue";
import SensorReturningSheets from "@/components/deliveryTracking/SensorReturningSheets.vue";

export default {
    name: 'DeliveryTracking',
    components: {
        SensorReturningSheets,
        TrackReturningSensor,
        TrackSensor,
    },
    data() {
        return {
            showList: 0
        }
    },
    methods: {
        ...Vuex.mapActions('deliveryTracking', {
            resetData: 'resetData'
        }),
        goTo(route, query = null) {
            if (query) {
                this.$router.push({
                    name: route,
                    query: {deliveryType: query}
                })
            } else {
                this.$router.push(route)
            }
        }
    },
    computed: {
        deliveryType() {
            return this.$router.currentRoute.query.deliveryType
        }
    },
    async created() {
        await this.resetData();
    }
}
</script>

<style lang="scss">
html {
    scroll-behavior: smooth
}

.content-cards-hover {
    min-height: 80px;
    transition: transform 330ms ease-in-out;
    transform: scale(1);

    &:hover {
        box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 20%) !important;
        transform: scale(1.01);
        transition: transform 200ms ease-in-out;
    }
}

.icon-square {
    width: 2.5em;
    height: 2.5em;
}
</style>
